import React, { useState, useEffect } from "react";
import _ from "lodash";
import Photos from "./Photos";
import { withCartIdParam } from "../../../../utils/BrowserHelper";
import { LightBoxViewCart } from "../vectors/AddToCartIcon";
import ContrastForegroundUtility from "../../../../utils/ContrastForegroundUtility";
import Telemetry from "../../../../utils/rudderstackAnalytics";

interface FilteredResultProps {
  album: any;
  event: any;
  cart: any;
  addPhotoToCart: (photo: any) => void;
  removePhotoFromCart: Function;
  loadingAlbums: boolean;
  listOfMediaId: { id: number; hidden: boolean }[];
  curationMode: boolean;
  userId: number;
  userSignedIn: boolean;
  registerGuestUserAPI: string;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  setGuestUser: (userId: number) => void;
  guestUserId: number | null;
  filteredPhotos: any;
  eventCoverPickerMode: boolean;
  isEmbed: boolean;
  cartId: number;
  loadPhotos: Function;
  filterSelections: object;
  reset: boolean;
  addAllToCart: Function;
  activeFilters: Function;
  pageConfiguration: { primaryColor: string };
}

const FilteredResult: React.FC<FilteredResultProps> = ({
  album,
  event,
  cart,
  addPhotoToCart,
  removePhotoFromCart,
  loadingAlbums,
  listOfMediaId,
  curationMode,
  userId,
  userSignedIn,
  registerGuestUserAPI,
  addPhotoToFavoritesAPI,
  removePhotoFromFavoritesAPI,
  setGuestUser,
  guestUserId,
  filteredPhotos,
  eventCoverPickerMode,
  isEmbed,
  cartId,
  loadPhotos,
  filterSelections,
  reset,
  addAllToCart,
  activeFilters,
  pageConfiguration
}: FilteredResultProps) => {
  const [photos, setPhotos] = useState(filteredPhotos);

  const updateFavorites = (photoId: number) => {
    const updatedPhotos = photos.filter(
      (photo: any) => photo.id != photoId && photo
    );
    setPhotos(updatedPhotos);
  };

  useEffect(() => {
    if (reset) {
      loadPhotos(filterSelections, true);
    }
  }, [reset]);

  const photosCount = () => (cart && cart.photos ? cart!.photos.length : 0);

  if (photos.length == 0) {
    return (
      <div className="dv-results">
        <div className="dv-event-no-albums">
          There are no photos matching your filters. Clear filters and try
          again.
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div
          className={
            "dv-filtered-result dv-album-details" +
            (eventCoverPickerMode ? " sticky-cover" : "")
          }
          translate="no"
        >
          <span>
            <h3>Filtered Photos</h3>
          </span>
          <div className="checkout ns filtered-photos-action-btns">
            {!curationMode && (
              <button
                className="geo-btn geo-btn--secondary-ghost geo-em__btn geo-addAll-btn"
                onClick={() => {
                  Telemetry.track("event-gallery:add-all-to-cart", {
                    eventId: event.id,
                    eventName: event.name,
                    cartId: cart.id
                  });
                  addAllToCart(activeFilters(), photos);
                }}
                style={{ marginRight: 10 }}
              >
                {" "}
                Add all to cart
              </button>
            )}
            {!eventCoverPickerMode && (
              <a
                href={withCartIdParam(
                  "/checkout?source=event-gallery",
                  cartId,
                  isEmbed
                )}
                target={isEmbed ? "_blank" : ""}
                className={
                  "btn  " +
                  (photosCount() > 0 ? " secondary-btn" : "disable-btn")
                }
                style={{
                  pointerEvents: photosCount() > 0 ? "initial" : "none"
                }}
              >
                <LightBoxViewCart
                  color={ContrastForegroundUtility.fgColor(
                    pageConfiguration.primaryColor
                  )}
                />
                <span
                  className="photos"
                  style={{
                    color: `${ContrastForegroundUtility.fgColor(
                      pageConfiguration.primaryColor
                    )}`
                  }}
                >
                  &nbsp;{photosCount()}
                </span>
              </a>
            )}
          </div>
        </div>
        <div>
          <Photos
            event={event}
            album={album[0]}
            photos={photos}
            cart={cart}
            addPhotoToCart={addPhotoToCart}
            removePhotoFromCart={removePhotoFromCart}
            loadingPhotos={loadingAlbums}
            loadMorePhotos={() => {}}
            updateAlbum={updateFavorites}
            collapsibleGallery={true}
            expanded={true}
            galleryRowsRendered={() => {}}
            listOfMediaId={listOfMediaId}
            curationMode={curationMode}
            userId={userId}
            userSignedIn={userSignedIn}
            registerGuestUserAPI={registerGuestUserAPI}
            addPhotoToFavoritesAPI={addPhotoToFavoritesAPI}
            removePhotoFromFavoritesAPI={removePhotoFromFavoritesAPI}
            setGuestUser={setGuestUser}
            guestUserId={guestUserId}
            filteredResult={true}
          />
        </div>
      </div>
    );
  }
};

export default FilteredResult;
