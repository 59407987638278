import React, { KeyboardEvent } from "react";
import * as EmailValidator from "email-validator";

type EmailCollectProps = {
  closeModal: () => void;
  registerGuestUserAPI: string;
  addToFavorites: ({ userId }: { userId: number }) => void;
  eventId: number;
  trackGuestFavoriteProvidedEmail: () => void;
};

const CollectCustomerEmailModal = ({
  closeModal,
  registerGuestUserAPI,
  addToFavorites,
  eventId,
  trackGuestFavoriteProvidedEmail
}: EmailCollectProps) => {
  const customerEmailField: any = React.createRef();

  const registerGuestUser = () => {
    const email = customerEmailField.current.value;
    const requestBody = {
      email: email.toLowerCase(),
      event_id: eventId
    };

    if (EmailValidator.validate(email)) {
      fetch(registerGuestUserAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Something went wrong Please try again.");
          }
          return response.json();
        })
        .then(data => {
          trackGuestFavoriteProvidedEmail();
          addToFavorites({
            userId: data.user_id
          });
          closeModal();
        })
        .catch(error => {
          const errorMessage = error.message.replace("Error: ", "");
          //@ts-ignore
          toastr.error(errorMessage);
        });
    }
    // @ts-ignore
    else toastr.error("The email you entered is not valid");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      registerGuestUser();
    }
  };

  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in confirm-modal-div geo-modal privacy-modal geo-multi-modal"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content geo-privacy-body geo-privacy-modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title geo-privacy-title">
                Please enter your email address to save favorites
              </span>
              <button
                type="button"
                className="close confirm-close"
                onClick={() => closeModal()}
              >
                <span>&times;</span>
              </button>{" "}
            </div>

            <div className="modal-body">
              <p>
                Click the Favorites icon in the filters panel to access your
                favorite items at any time
              </p>
              <div>
                <input
                  type="email"
                  placeholder="Email address"
                  ref={customerEmailField}
                  className="geo-round-input geo-form__pbg"
                  onKeyDown={handleKeyDown}
                />

                <p className="geo-mt-1" style={{ fontSize: "12px" }}>
                  You will be notified when all photos have been uploaded.
                </p>
              </div>
              <div className="geo-modal__actions geo-collect-email-modal-footer">
                <button
                  className="geo-btn geo-btn--secondary geo-btn--caps"
                  onClick={registerGuestUser}
                >
                  {" "}
                  Done{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default CollectCustomerEmailModal;
