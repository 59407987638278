import React, { useEffect, useState } from "react";
import SelfieUploadPopup from "../../../MasterImageSearch/components/SelfieUploadPopup/index";
import { SelfieSearchContext } from "../../../MasterImageSearch/components/SelfieSearchContext";

const lockicon = require("../../styles/lock-dark.svg");
const selfieIllus = require("../../styles/selfi-illustration.svg");

const PrivateScreen = ({
  selfieSearchS3Bucket,
  updateFilters,
  sideBarOpenStyling,
  showPrepaidClearCartWarning,
  promptClearPrepaidCart,
  hideUploadSelfie,
  trackPrivateEventSelfieUploadFlow,
  trackSelfieFilterApplied,
  pageConfiguration
}: {
  selfieSearchS3Bucket: any;
  updateFilters: any;
  sideBarOpenStyling: Function;
  promptClearPrepaidCart: Function;
  showPrepaidClearCartWarning: boolean;
  hideUploadSelfie: boolean;
  trackPrivateEventSelfieUploadFlow: (trackEventName: string) => void;
  trackSelfieFilterApplied: () => void;
  pageConfiguration: {
    primaryColor: string;
    secondaryColor: string;
    accentColor: string;
  };
}) => {
  const [selfieUploadPopup, toggleSelfieUploadPopup] = useState(false);

  return (
    <div className={"geo-private-wrap-outer"}>
      <div className="geo-private-wrap ns">
        <h5 className="geo-private-head">
          <img src={selfieIllus} className="geo-private-lock" />
          <strong className="geo-private-title">
            It's easy to find your photos!
          </strong>
        </h5>
        <p className="geo-private-desc">
          Simply upload a <strong>selfie</strong> to view your matching photos
          instantly.
        </p>
        <button
          className="btn btn-primary geo-private-btn"
          onClick={() => {
            trackPrivateEventSelfieUploadFlow(
              "event-gallery:private-event:selfie-button-clicked"
            );
            showPrepaidClearCartWarning
              ? promptClearPrepaidCart()
              : toggleSelfieUploadPopup(true);
          }}
        >
          {hideUploadSelfie ? "Take" : "Upload"} a Selfie
        </button>
        {selfieUploadPopup && (
          <SelfieSearchContext.Provider
            //@ts-ignore
            value={{
              selfieSearchS3Bucket: selfieSearchS3Bucket,
              pageConfiguration: pageConfiguration
            }}
          >
            <SelfieUploadPopup
              updateImageFilter={imageURL => {
                updateFilters(
                  {
                    referenceImage: imageURL
                  },
                  false
                );
                toggleSelfieUploadPopup(false);
                trackPrivateEventSelfieUploadFlow(
                  "event-gallery:private-event:selfie-uploaded"
                );
                trackSelfieFilterApplied();
              }}
              closePopup={() => {
                toggleSelfieUploadPopup(false);
              }}
              hideUploadSelfie={hideUploadSelfie}
            />
          </SelfieSearchContext.Provider>
        )}
      </div>
    </div>
  );
};

export default PrivateScreen;
