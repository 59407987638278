import PropTypes from "prop-types";
import React from "react";
import Color from "color";
import ContrastForegroundUtility from "../../../utils/ContrastForegroundUtility";

interface StyleOverridesProps {
  primaryColor: string;
  secondaryColor: string;
}

class StyleOverrides extends React.Component<StyleOverridesProps> {
  static propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string
  };
  lightColor: string;

  constructor(props: StyleOverridesProps) {
    super(props);
    this.lightColor = ContrastForegroundUtility.fgColor(this.fgColor);
  }
  fgColor: any = (color: string) => ContrastForegroundUtility.fgColor(color);

  hoverColor: any = (color: string) => Color(color).darken(0.2).hex();

  render() {
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .btn-primary { 
        background-color: ${this.props.primaryColor};
        border-color: ${this.props.primaryColor}; 
        color: ${this.fgColor(this.props.primaryColor)} !important;
      }
      .btn.btn-primary.no-fill {
        color: ${this.props.primaryColor};
      }
      .btn-primary:hover,
      .btn-primary:active, 
      .btn-primary:active:hover,
      .dropdown.dv-browseevent a.btn-primary:hover,
      .dropdown.dv-browseevent a.btn-primary:active,
      .dropdown.dv-browseevent a.btn-primary:active:hover,
      .open > .dropdown-toggle.btn-primary {
        background-color: ${this.hoverColor(this.props.primaryColor)};
        border-color: ${this.hoverColor(this.props.primaryColor)} !important;
        color: ${this.fgColor(this.props.primaryColor)} !important;
      }
      .btn-primary.btn-primary-white, 
      .btn-primary.btn-primary-white:active {
        border-color: ${this.props.primaryColor}; 
        color: ${this.props.primaryColor}; 
      }
      .btn-primary.btn-primary-white:hover {
        color: ${this.fgColor(this.props.primaryColor)};
      }
      .geo-btn--secondary-ghost{
        border-color: ${this.props.secondaryColor} !important; 
      }
      .geo-btn--secondary-ghost:focus{
        color: ${this.fgColor(this.props.secondaryColor)}!important;
        background: ${this.props.secondaryColor} !important;
      }
      .geo-btn--secondary-ghost:hover{
        color: ${this.fgColor(this.props.secondaryColor)} !important;
        background: ${this.props.secondaryColor} !important;
      }
      .geo-addAll-btn{
        color: ${this.props.primaryColor}!important;
      }
      .geo-remove-btn{
        color: white !important;
      }
      .offer-details {
        font-weight: 800er;
        color: ${this.fgColor(this.props.primaryColor)};
      }
      .dv-eventsfilter h4{
        color: ${this.props.primaryColor};
        margin-left:1px;
      }
      .custom-clr {
        background: ${this.props.primaryColor};
      }
      .custom-clr:hover {
        background: ${this.hoverColor(this.props.primaryColor)};
      }
      .text-clr{
        color:${this.props.primaryColor} !important;
      }
      .dv-progress .dv-progress-bar {
        background: ${this.props.primaryColor};
      }
      a, a:hover {
        color: ${this.props.primaryColor};
      }
      .slider-selection,
      .slider-handle {
        background: ${this.props.primaryColor};
      }
      .ul-checkbox li input ~ span:before {
        border: ${this.props.primaryColor} 1px solid;
      }
      .ul-checkbox li input:checked ~ span:before {
        background: ${this.props.primaryColor};
      }
      .form-control:focus {
        border-color: ${this.props.primaryColor};
      }
      .dv-imgdetails-text a {
        color: ${this.props.primaryColor};
      }
      .ns button.secondary-btn, .ns .secondary-btn {
        background: ${this.props.secondaryColor};
        box-shadow: none;
        color: ${this.fgColor(this.props.secondaryColor)} !important;
      }
      .ns button.secondary-btn:hover, .ns .secondary-btn:hover {
        background: ${this.hoverColor(this.props.secondaryColor)} !important;
        color: ${this.fgColor(this.props.secondaryColor)} !important;
      }
      .dv-section-footer a {
        background: ${this.props.primaryColor};
        color: ${this.fgColor(this.props.primaryColor)};
      }
      .dv-section-footer a:hover {
        color: ${this.fgColor(this.props.primaryColor)};
      }
      .offer-banner,
      .prepaid-customer-limit-welcome {
        background: ${this.props.primaryColor};
      }
      .offer-banner-body{
        color: ${this.fgColor(this.props.primaryColor)};
      }
      .offer--multi-tier hr {
        border-color: ${this.fgColor(this.props.primaryColor)};
      }
      .offer-active {
        position: relative;
        z-index: 100;
        background-color: white;
        font-size: 8.5px;
        top: -17px;
        padding: 0px 6px;
        border-radius: 3px;
        border: 1px solid ${this.props.primaryColor};
        color:black !important;
        height:fit-content;
        width:fit-content;
        margin-bottom: -16px;
      }
      .offer-banner-welcome,
      .cart-count {
        background: ${this.props.primaryColor};
        color: ${this.fgColor(this.props.primaryColor)};
      }
      .cart-count {
        background: ${this.lightColor};
        color: ${this.fgColor(this.props.primaryColor)};
      }
      .banner-button,
      .count-badge,
      .prepaid-customer-cart-badge {
        background: ${this.fgColor(this.props.primaryColor)};
        color: ${this.lightColor};
      }
      .dash-border-web {
        border: 1px dashed ${this.props.primaryColor};
        border-radius: 5px;
        padding: 1px;
        min-width:173px;
      }
      .check-radio input:checked + .r-dot {
        background-color: ${this.props.primaryColor} !important;
      }
    `
        }}
      />
    );
  }
}

export default StyleOverrides;
