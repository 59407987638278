import _ from "lodash";
import React from "react";
import ActiveIcon from "../vectors/ActiveIcon";

const selfieIcon = require("../../styles/selfie-icon.svg");
const timeIcon = require("../../styles/time-icon.svg");
const album = require("../../styles/album.svg");
const bibIcon = require("../../styles/bib-icon.svg");
const photographer = require("../../styles/photographer.svg");
const favorite = require("../../styles/favourite.svg");
const toggleOut = require("../../styles/toggleOut.svg");

interface FilterSidebarProps {
  toggleSidebar: Function;
  collapseFilter: Function;
  pageConfig: {
    primaryColor: string;
    accentColor: string;
  };

  hidePhotographersFilter: boolean;
  enableFilterByText: boolean;
  albumModeID: string | null;
  filterSelections: {
    albums: Array<{}>;
    photographers: Array<{}>;
    photoTime: {
      minuteFrom: number;
      minuteTo: number;
      active: boolean;
    };
    photoText: string | null;
    referenceImage?: string | null;
    favoritesSelected: boolean;
  };
}

const FilterSidebar = ({
  toggleSidebar,
  collapseFilter,
  pageConfig,
  hidePhotographersFilter,
  enableFilterByText,
  filterSelections,
  albumModeID
}: FilterSidebarProps) => {
  const filters = [
    {
      name: "Selfie",
      icon: selfieIcon,
      isActive: filterSelections.referenceImage
    },
    {
      name: "Favorites",
      icon: favorite,
      isActive: filterSelections.favoritesSelected
    },
    {
      name: "Time",
      icon: timeIcon,
      isActive: filterSelections.photoTime.active
    },
    {
      name: "Album",
      icon: album,
      isActive: filterSelections.albums.length > 0
    },
    {
      name: "Bib",
      icon: bibIcon,
      isActive: filterSelections.photoText
    },
    {
      name: "Photographer",
      icon: photographer,
      isActive: filterSelections.photographers.length > 0
    }
  ];
  const updateFilters = () => {
    if (!enableFilterByText)
      _.remove(
        filters,
        (filter: { name: string; icon: any }) => filter.name == "Bib"
      );
    if (hidePhotographersFilter)
      _.remove(
        filters,
        (filter: { name: string; icon: any }) => filter.name == "Photographer"
      );
    if (albumModeID)
      _.remove(
        filters,
        (filter: { name: string; icon: any }) => filter.name == "Album"
      );

    return filters;
  };

  const renderFilterBlock = (filter: {
    name: any;
    icon: any;
    isActive: boolean;
  }) => (
    <div
      className="filter-block"
      key={filter.name}
      onClick={e => (
        toggleSidebar(), collapseFilter(e, filter.name.toLowerCase(), true)
      )}
    >
      {filter.isActive && (
        <div className="active-icon">
          <ActiveIcon color={pageConfig.accentColor} />
        </div>
      )}
      <img className="filter-icon" src={filter.icon} />
      <span className="filter-text">{filter.name}</span>
    </div>
  );

  return (
    <div className=" col-md-1 dv-sidebar">
      <div className="dv-filtersIcon" onClick={() => toggleSidebar()}>
        <span className="dv-filtertoggle">
          <svg
            className="filter-icon"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9514 0.205411C13.9162 0.143328 13.8639 0.0914293 13.7999 0.0552527C13.736 0.0190762 13.6628 -2.07112e-05 13.5882 1.68559e-08H0.411785C0.337254 -3.47279e-06 0.264119 0.0190983 0.200181 0.0552681C0.136242 0.091438 0.0838967 0.143319 0.048727 0.20538C0.0135574 0.267441 -0.00311762 0.337353 0.000480104 0.407661C0.00407782 0.477969 0.0278134 0.546037 0.0691557 0.604605L4.94119 7.5066V13.6111C4.94126 13.7142 4.98466 13.8131 5.06187 13.886C5.13907 13.9589 5.24377 13.9999 5.35296 14C5.38658 14.0001 5.42009 13.9962 5.45268 13.9883L8.7468 13.2106C8.8359 13.1895 8.91501 13.141 8.97155 13.0727C9.02809 13.0043 9.05881 12.9201 9.05883 12.8333V7.5066L13.9309 0.604528C13.9722 0.54596 13.9959 0.477921 13.9995 0.407644C14.0031 0.337366 13.9865 0.267478 13.9514 0.205411Z"
              fill={pageConfig.primaryColor}
            />
          </svg>
          <span
            className="filter-text"
            style={{ color: pageConfig.primaryColor, fontWeight: "bolder" }}
          >
            Filters
          </span>
        </span>
        <img
          className="toggleOut-icon"
          src={toggleOut}
          onClick={() => toggleSidebar()}
        />
      </div>
      {updateFilters().map(filter => renderFilterBlock(filter))}
    </div>
  );
};

export default FilterSidebar;
