import React from "react";
import PropTypes, { checkPropTypes } from "prop-types";
import ajax from "superagent";

const lockicon = require("../../styles/lock-dark.svg");
import { Event } from "../types";

interface PasswordProtectedProps {
  event: Event;
  unlockEvent: Function;
  updateUserInputPassword: Function;
  authenticateEventPasswordAPI?: string;
  sideBarOpenStyling: Function;
}

interface PasswordProtectedState {
  loader: boolean;
  invalidPassword?: boolean;
}

class PasswordProtected extends React.Component<
  PasswordProtectedProps,
  PasswordProtectedState
> {
  static contextTypes = {
    eventPassword: PropTypes.string,
    currentUserHasPasswordAccess: PropTypes.bool,
    authenticateEventPasswordAPI: PropTypes.string
  };

  eventPasswordInput: React.RefObject<HTMLInputElement> | null;
  constructor(props: PasswordProtectedProps) {
    super(props);
    this.state = {
      loader: false,
      invalidPassword: false
    };
    this.eventPasswordInput = React.createRef();
  }

  passwordFormSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ loader: true }, () => this.authenticatePassword());
  };

  authenticatePassword = () => {
    ajax
      .get(this.context.authenticateEventPasswordAPI)
      .query({
        event_password: this.eventPasswordInput?.current?.value
      })
      .then(res => {
        if (res.body.authenticated) {
          this.props.updateUserInputPassword(
            this.eventPasswordInput?.current?.value
          );
          this.props.unlockEvent();
          this.updateInvalidPassword(false);
        } else {
          this.updateInvalidPassword(true);
        }
      });
  };

  updateInvalidPassword = (invalid: boolean) => {
    this.setState({ invalidPassword: invalid, loader: false });
  };

  mailtoEventOrganiser = () => {
    return (
      `mailto:${this.props.event.organiserEmail}?cc=support@geosnapshot.com&subject=Password request for ${this.props.event.name}&body=` +
      encodeURIComponent(
        `Hi ${this.props.event.organiserFirstName}, I’m requesting the password for the event ${this.props.event.name} could you please reply to this email with the password so I can view and purchase the photos.`
      )
    );
  };

  render() {
    return (
      <div className={"dv-results geo-protected-fw"}>
        <div className="dv-event-password-form geo-private-wrap ns">
          <h5 className="geo-private-head">
            <img src={lockicon} className="geo-private-lock" />
            <strong>This event is protected</strong>
          </h5>
          <p>Please enter the password provided by the Event Organiser</p>
          <form onSubmit={this.passwordFormSubmit}>
            <div
              className={
                "geo-pvt-input-wrap" +
                (this.state.invalidPassword ? " has-error" : "")
              }
            >
              <input
                type="text"
                className="form-control"
                id="eventPassword"
                placeholder="Event Password"
                // @ts-ignore
                defaultValue={
                  this.context.currentUserHasPasswordAccess
                    ? this.context.eventPassword
                    : ""
                }
                ref={this.eventPasswordInput}
              />
              {this.state.invalidPassword ? (
                <span className="help-block">
                  The password you've given is incorrect.
                </span>
              ) : null}
              <button
                className="btn btn-primary"
                type="button"
                disabled={this.state.loader}
                onClick={this.passwordFormSubmit}
              >
                {!this.state.loader ? (
                  <span>Unlock</span>
                ) : (
                  <>
                    <span>Please wait </span>
                    <span className="fa fa-circle-o-notch fa-spin"></span>
                  </>
                )}
              </button>
            </div>
          </form>
          <p>
            Contact the{" "}
            <a href={this.mailtoEventOrganiser()}>Event Organiser</a> to get the
            password
          </p>
        </div>
      </div>
    );
  }
}

export default PasswordProtected;
