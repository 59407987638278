import React, { Suspense } from "react";
import ajax from "superagent";
import _ from "lodash"; // TODO: load only what's needed from lodash-es
import AlbumsContainer from "./albums/AlbumsContainer";
import FilterSidebar from "./filters/FilterSidebar";
import BundleDiscountRemovePopup from "./BundleDiscountRemovePopup";
import {
  deleteCookie,
  setCookie,
  getCookie,
  withCartIdParam
} from "../../../utils/BrowserHelper";
import PropTypes from "prop-types";
import { Album, Event } from "./types";
import InvitePhotographerPopup from "../../common/InvitePhotographerPopup";

const FiltersContainer = React.lazy(() => import("./filters/FiltersContainer"));
const OffersBanner = React.lazy(() => import("./offerBanners/index"));

interface EventContainerProps {
  event: Event;
  albumsAPI: string;
  addPhotoToCart: Function;
  removePhotoFromCart: Function;
  emptyPhotosFromCart: Function;
  cart?: {
    photos: Array<{}>;
    photoBundleDiscountAvailed?: boolean;
    photoBundleDiscountEventId: number | null;
    id: number;
  };
  initialFilters: {
    albums?: Album[];
    photographers?: Array<{}>;
  };
  photoTimeRange: {
    min: number;
    max: number;
  };
  discountMessage: string;
  registerCustomerLeadAPI: string;
  registerGuestUserAPI: string;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  userSignedIn: boolean;
  userId: number;
  userEmail: string;
  prepaidCustomersEnabled: boolean;
  promptPrepaidCustomerAuth: Function;
  logoutPrepaidCustomer: Function;
  selfieSearchS3Bucket: string | undefined;
  photoBundleDiscountAvailable?: boolean;
  removePhotoBundleDiscount?: Function | null;
  setSelfieUploadPopup?: Function;
  selfieUploadPopup?: boolean;
  photosAvailable: boolean;
  handleSelfiePrepaidCustomerCreation: Function;
  curationMode: boolean;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  setFilterActive?: Function;
  unlinkSelfiePrepaidCustomer: Function;
  clearCartPopup: boolean;
  setClearCartPopup: Function;
  cartFullPrice: string;
  cartDiscountedPrice: string;
  cartHasDiscount: boolean;
  setGuestUser: (userId: number) => void;
  guestUserId: null | number;
  availPhotoBundleDiscount: () => void;
  showGalleryActions?: Function;
  setAlbum: (val: any) => void;
  album: Album[] | null;
}

interface EventContainerState {
  albums: Album[];
  filterSelections: {
    albums: Array<{}>;
    photographers: Array<{}>;
    photoTime: {
      minuteFrom: number;
      minuteTo: number;
      active: boolean;
    };
    referenceImage: string | null;
    photoText: string | null;
    favoritesSelected: boolean;
  };
  filterOptions: {
    albums: [];
    photographers: [];
    photoTimeRange?: any;
  };
  unlockedPrivateEvent: boolean;
  promptRemoveOffer: boolean;
  sidebarOpen: boolean;
  width: number;
  selfieFilterExpanded: boolean;
  timeFilterExpanded: boolean;
  albumFilterExpanded: boolean;
  photographerFilterExpanded: boolean;
  bibFilterExpanded: boolean;
  favoritesFilterExpanded: boolean;
  zIndex: boolean;
  clearAllFilters?: boolean;
  removeSelfie: boolean;
}

class EventContainer extends React.Component<
  EventContainerProps,
  EventContainerState
> {
  defaultFilters: {
    albums: never[];
    photographers: never[];
    photoTime: {
      minuteFrom: number; // 00:00 to minutes
      minuteTo: number; // 23:59 to minutes
      active: boolean;
    };
    referenceImage: string | null;
    photoText: null;
    favoritesSelected: false;
  };
  static contextTypes = {
    prepaidCustomer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      eventId: PropTypes.number
    }),
    cartId: PropTypes.number,
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string
    }),
    hidePhotographersFilter: PropTypes.bool,
    enableFilterByText: PropTypes.bool,
    clearMediaIds: PropTypes.func,
    setPhotos: PropTypes.func,
    curationMode: PropTypes.bool,
    setSelectAll: PropTypes.func,
    canManageTags: PropTypes.bool,
    selfiePrepaidStatus: PropTypes.string,
    showPrepaidClearCartWarning: PropTypes.bool,
    promptClearPrepaidCart: PropTypes.func,
    setFilterSelections: PropTypes.func,
    albumModeID: PropTypes.string
  };
  constructor(props: EventContainerProps) {
    super(props);

    this.defaultFilters = {
      albums: [],
      photographers: [],
      photoTime: {
        minuteFrom: props.photoTimeRange.min || 0, // 00:00 to minutes
        minuteTo: props.photoTimeRange.max || 1439, // 23:59 to minutes
        active: false
      },
      referenceImage: null,
      photoText: null,
      favoritesSelected: false
    };

    this.state = {
      albums: [],
      filterSelections: {
        ...this.defaultFilters,
        ...props.initialFilters,
        //@ts-ignore
        referenceImage: this.getReferenceImage()
      },
      filterOptions: {
        albums: [],
        photographers: [],
        photoTimeRange: props.photoTimeRange
      },
      unlockedPrivateEvent: props.event.isPrivate
        ? this.referenceImageAvailable()
        : true,
      promptRemoveOffer: false,
      sidebarOpen: this.referenceImageAvailable(),
      width: 0,
      selfieFilterExpanded: this.referenceImageAvailable(),
      timeFilterExpanded: false,
      albumFilterExpanded: false,
      photographerFilterExpanded: false,
      bibFilterExpanded: false,
      favoritesFilterExpanded: false,
      zIndex: false,
      removeSelfie: false
    };

    this.updateFilters = this.updateFilters.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.activeFilters = this.activeFilters.bind(this);
    this.clearSelfie = this.clearSelfie.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.collapseFilter = this.collapseFilter.bind(this);
    this.changeZindex = this.changeZindex.bind(this);
  }
  collapseFilter = (event: any, filter: string, condition: any) => {
    event.preventDefault();
    this.setState({
      [`${filter}FilterExpanded`]: condition
        ? condition
        : //@ts-ignore
          !this.state[`${filter}FilterExpanded`]
    } as Pick<EventContainerState, keyof EventContainerState>);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  referenceImageAvailable = () => (this.getReferenceImage() ? true : false);

  clearSelfie() {
    if (
      this.props.cart &&
      this.props.cart.photoBundleDiscountAvailed &&
      !this.state.promptRemoveOffer
    ) {
      this.setState({ promptRemoveOffer: true, removeSelfie: true });
    } else if (this.context.showPrepaidClearCartWarning) {
      this.context.promptClearPrepaidCart();
    } else {
      this.setState({ sidebarOpen: false }, () =>
        this.updateFilters(
          {
            ["referenceImage"]: null
          },
          false
        )
      );
    }
  }

  removeBundleDiscountOffer = async (availPrepaidOffer: any) => {
    if (this.state.clearAllFilters) {
      this.updateFilters(null, true);
    }
    this.setState({ promptRemoveOffer: false });
    this.props.removePhotoBundleDiscount &&
      (await this.props.removePhotoBundleDiscount());
    if (this.state.removeSelfie) {
      this.setState({ removeSelfie: false }, () =>
        this.updateFilters(
          {
            ["referenceImage"]: null
          },
          false
        )
      );
    }

    if (availPrepaidOffer) {
      this.props.promptPrepaidCustomerAuth();
    }
  };

  resetFilter() {
    if (this.props.cart && this.props.cart.photoBundleDiscountAvailed) {
      this.setState({ promptRemoveOffer: true, clearAllFilters: true });
    } else if (this.context.showPrepaidClearCartWarning) {
      this.context.promptClearPrepaidCart();
    } else if (this.context.albumModeID) {
      this.updateFilters(
        {
          ...this.defaultFilters,
          albums: this.props.album && [this.props.album[0].id]
        },
        false
      );
    } else {
      this.updateFilters(null, true);
    }
  }

  toggleSidebar() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  unlockPrivateEvent = () => {
    this.setState({
      unlockedPrivateEvent: true
    });
  };

  lockPrivateEvent = () => {
    this.setState({
      unlockedPrivateEvent: false
    });
  };

  getReferenceImage = () => {
    const cookie = getCookie("reference_image_for_event");
    const [eventId, referenceImage] = cookie ? cookie.split("-imageURL-") : [];
    const photoBundleSelfie =
      this.props.cart &&
      this.props.cart.photoBundleDiscountEventId === this.props.event.id &&
      this.props.cart.photoBundleDiscountAvailed
        ? cookie
        : null;

    const parsedEventId = eventId ? parseInt(eventId) : null;

    if (
      (!cookie && !photoBundleSelfie) ||
      (cookie && parsedEventId != this.props.event.id)
    ) {
      //return null if none of these exist or if cookie exists but the event ID doesn't match
      return null;
    } else if (
      photoBundleSelfie ||
      (parsedEventId === this.props.event.id && referenceImage)
    ) {
      //return reference image if either of the above conditions are true
      return referenceImage;
    }
    return null;
  };

  hasValidDiscount = () =>
    this.props.event.hasDiscount && this.props.event.price !== 0;

  togglePrivateEvent = () => {
    if (this.state.filterSelections.referenceImage) this.unlockPrivateEvent();
    else this.lockPrivateEvent();
  };

  componentDidMount() {
    if (this.props.setFilterActive && this.referenceImageAvailable())
      this.props.setFilterActive(true);

    const fetchAlbums = (
      albumsAPI: string,
      cartId: number | null,
      albumModeID: string
    ) => {
      return ajax.get(withCartIdParam(albumsAPI, cartId)).query({
        album_id: albumModeID
      });
    };

    const handleResponse = (response: { body: { albums: any } }) => {
      let albums = response.body.albums;
      this.setState(
        {
          albums: albums,
          //@ts-ignore
          filterSelections: this.context.albumModeID
            ? {
                ...this.state.filterSelections,
                albums: [albums[0].id]
              }
            : this.state.filterSelections,
          filterOptions: {
            albums: albums.map((album: { id: any; name: any }) => ({
              id: album.id,
              name: album.name
            })),
            //@ts-ignore
            photographers: _.uniqBy(
              _.map(albums, album => ({
                id: album.photographerId,
                name: album.photographerName,
                albumsCount: _.filter(albums, {
                  photographerId: album.photographerId
                }).length
              })),
              "id"
            )
          }
        },
        () => {
          this.context?.setFilterSelections?.(this.state.filterSelections);
          this.context.albumModeID && this.props.setAlbum(this.state.albums);
          if (this.context.canManageTags) {
            this.context.setSelectAll();
            this.context.clearMediaIds();
            this.context.setPhotos([]);
          }
        }
      );
    };

    function getAlbumIdFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const albumId = urlParams.get("album_id");
      if (albumId) {
        return albumId;
      } else {
        return null;
      }
    }

    fetchAlbums(
      this.props.albumsAPI,
      this.context.cartId,
      this.context.albumModeID || getAlbumIdFromUrl()
    ).then(handleResponse, () => console.log("request failed"));

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    if (!this.context.prepaidCustomer) {
      this.handleSelfiePrepaidCustomerCreation();
    }
  }

  handleSelfiePrepaidCustomerCreation = () => {
    if (
      this.props.event.prepaidCustomersEnabled &&
      this.props.event.prepaidCustomersPrimaryAuthField == "selfie" &&
      this.state.filterSelections.referenceImage
    ) {
      this.props.handleSelfiePrepaidCustomerCreation(
        this.state.filterSelections.referenceImage
      );
    }
  };

  filterOpen = (
    newFilters: { [x: string]: any; referenceImage?: string | null } | null,
    clearFilters: boolean,
    filter: string
  ) =>
    newFilters?.referenceImage
      ? true
      : clearFilters || newFilters?.referenceImage === null
      ? false
      : //@ts-ignore
        this.state[filter];

  updateFilters(
    newFilters: { [x: string]: any; referenceImage?: string | null } | null,
    clearFilters: boolean
  ) {
    this.setState(
      {
        filterSelections: {
          ...(clearFilters ? this.defaultFilters : this.state.filterSelections),
          ...newFilters
        },
        sidebarOpen: this.filterOpen(newFilters, clearFilters, "sidebarOpen"),
        promptRemoveOffer: clearFilters ? false : this.state.promptRemoveOffer,
        selfieFilterExpanded: this.filterOpen(
          newFilters,
          clearFilters,
          "selfieFilterExpanded"
        )
      },
      () => {
        if (this.context.canManageTags) {
          this.context.setSelectAll();
          this.context.clearMediaIds();
          this.context.setPhotos([]);
        }
        if (clearFilters || newFilters?.referenceImage === null) {
          deleteCookie("reference_image_for_event");
          if (this.prepaidAuthSelfie) {
            this.props.unlinkSelfiePrepaidCustomer();
          }
        } else if (this.state.filterSelections.referenceImage != undefined) {
          setCookie(
            "reference_image_for_event",
            `${this.props.event.id}-imageURL-${this.state.filterSelections.referenceImage}`
          );
        }
        this.context?.setFilterSelections?.(this.state.filterSelections);
        this.handleSelfiePrepaidCustomerCreation();
        if (this.props.event.isPrivate) this.togglePrivateEvent();
        const filter = this.activeFilters();
        filter.length > 0 && this.props.setFilterActive
          ? this.props.setFilterActive(true)
          : this.props.setFilterActive && this.props.setFilterActive(false);
      }
    );
  }

  activeFilters() {
    const filters = this.state.filterSelections;
    let activeFilters = [];

    if (filters.albums.length > 0) activeFilters.push("albums");
    if (filters.favoritesSelected) activeFilters.push("favorite");
    if (filters.photographers.length > 0) activeFilters.push("photographers");
    if (filters.photoTime.active) activeFilters.push("time");
    if (!!filters.photoText) activeFilters.push("bib_number");
    if (!!filters.referenceImage) activeFilters.push("selfie");

    return activeFilters;
  }

  addToCart(photo: any) {
    this.props.addPhotoToCart(photo, this.activeFilters());
  }

  prepaidAuthWithNoSelfie() {
    return (
      this.props.prepaidCustomersEnabled &&
      this.props.event.prepaidCustomersPrimaryAuthField == "selfie" &&
      (this.context.selfiePrepaidStatus == "unavailable" ||
        !this.state.filterSelections.referenceImage)
    );
  }

  prepaidAuthSelfie =
    this.props.prepaidCustomersEnabled &&
    this.props.event.prepaidCustomersPrimaryAuthField == "selfie";

  selfiePrepaidCustomer = () =>
    this.prepaidAuthSelfie && this.context && this.context.prepaidCustomer;

  renderBanner() {
    if (window.location.href.indexOf("event_cover_photo_picker") > 0) {
      return null;
    }
    return this.props.prepaidCustomersEnabled || this.hasValidDiscount() ? (
      this.prepaidAuthWithNoSelfie() ? null : (
        <div className="prepaid-customer-header">
          <Suspense fallback={<div>Loading ...</div>}>
            <OffersBanner
              //@ts-ignore
              photoBundleDiscountAvailed={
                this.props.cart?.photoBundleDiscountAvailed as
                  | boolean
                  | undefined
              }
              promptPrepaidCustomerAuth={this.props.promptPrepaidCustomerAuth}
              logoutPrepaidCustomer={this.props.logoutPrepaidCustomer}
              event={this.props.event}
              cartCount={this.props.cart ? this.props.cart.photos.length : 0}
              discountMessage={this.props.discountMessage}
              cartFullPrice={this.props.cartFullPrice}
              cartDiscountedPrice={this.props.cartDiscountedPrice}
              cartHasDiscount={this.props.cartHasDiscount}
              selfieSearchS3Bucket={this.props.selfieSearchS3Bucket}
              prepaidCustomersEnabled={this.props.prepaidCustomersEnabled}
              photoBundleDiscountAvailable={
                this.props.photoBundleDiscountAvailable
              }
              updateFilters={this.updateFilters}
              promptRemoveOffer={this.state.promptRemoveOffer}
              emptyPhotosFromCart={this.props.emptyPhotosFromCart}
              showPromptRemoveOffer={() =>
                this.setState({ promptRemoveOffer: true })
              }
              selectedImage={this.state.filterSelections.referenceImage}
              getReferenceImage={this.getReferenceImage}
              removeBundleDiscountOffer={this.removeBundleDiscountOffer}
              setSelfieUploadPopup={this.props.setSelfieUploadPopup}
              selfieUploadPopup={this.props.selfieUploadPopup}
              eventURL={undefined}
              prepaidCustomer={this.context.prepaidCustomer}
              cartPrepaidPhotos={undefined}
              photoBundleDiscountEventId={undefined}
              clearCartPopup={this.props.clearCartPopup}
              setClearCartPopup={this.props.setClearCartPopup}
              availPhotoBundleDiscount={this.props.availPhotoBundleDiscount}
            />
          </Suspense>
        </div>
      )
    ) : null;
  }

  renderWarningPopup() {
    return (
      this.state.promptRemoveOffer &&
      this.props.cart &&
      this.props.cart.photoBundleDiscountAvailed && (
        <BundleDiscountRemovePopup
          removePhotoBundleDiscount={this.removeBundleDiscountOffer}
          hideRemoveOfferPrompt={() => {
            this.setState({ promptRemoveOffer: false });
          }}
        />
      )
    );
  }

  changeZindex = () => {
    this.setState({ zIndex: !this.state.zIndex });
  };

  render() {
    return (
      <div className="dv-maincontent">
        <section className="geo-cg-wrap">
          {this.renderBanner()}
          {this.renderWarningPopup()}
          <div className="row dv-events-wrap">
            {this.props.showGalleryActions &&
            this.props.showGalleryActions() &&
            this.props.photosAvailable ? (
              this.state.sidebarOpen || this.state.width < 992 ? (
                <div
                  className="col-md-3 dv-filter-wrap"
                  style={{ zIndex: this.state.zIndex ? 19999 : 1 }}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <FiltersContainer
                      photoBundleDiscountAvailed={
                        this.props.cart
                          ? this.props.cart.photoBundleDiscountAvailed
                          : undefined
                      }
                      filterSelections={this.state.filterSelections}
                      selectedImage={this.state.filterSelections.referenceImage}
                      filterOptions={this.state.filterOptions}
                      updateFilters={this.updateFilters}
                      resetFilter={this.resetFilter}
                      photoTimeRange={this.props.photoTimeRange}
                      toggleSidebar={this.toggleSidebar}
                      width={this.state.width}
                      selfieFilterExpanded={this.state.selfieFilterExpanded}
                      timeFilterExpanded={this.state.timeFilterExpanded}
                      albumFilterExpanded={this.state.albumFilterExpanded}
                      photographerFilterExpanded={
                        this.state.photographerFilterExpanded
                      }
                      bibFilterExpanded={this.state.bibFilterExpanded}
                      favoritesFilterExpanded={
                        this.state.favoritesFilterExpanded
                      }
                      collapseFilter={this.collapseFilter}
                      changeZindex={this.changeZindex}
                      clearSelfie={this.clearSelfie}
                      isPhotoIndexingDisabled={
                        this.props.event.isPhotoIndexingDisabled
                      }
                      getReferenceImage={this.getReferenceImage}
                      cartCount={
                        this.props.cart ? this.props.cart.photos.length : 0
                      }
                      setClearCartPopup={this.props.setClearCartPopup}
                      browsePhotosPath={this.props.event.browsePhotosPath}
                      event={this.props.event}
                      activeFilters={this.activeFilters}
                    />
                  </Suspense>
                </div>
              ) : (
                <FilterSidebar
                  toggleSidebar={this.toggleSidebar}
                  collapseFilter={this.collapseFilter}
                  pageConfig={this.context.pageConfiguration}
                  hidePhotographersFilter={this.context.hidePhotographersFilter}
                  enableFilterByText={this.context.enableFilterByText}
                  filterSelections={this.state.filterSelections}
                  albumModeID={this.context.albumModeID}
                />
              )
            ) : null}
            <AlbumsContainer
              event={this.props.event}
              photosAvailable={this.props.photosAvailable}
              albums={this.state.albums}
              filterSelections={this.state.filterSelections}
              cart={this.props.cart}
              addPhotoToCart={this.addToCart}
              removePhotoFromCart={this.props.removePhotoFromCart}
              registerCustomerLeadAPI={this.props.registerCustomerLeadAPI}
              registerGuestUserAPI={this.props.registerGuestUserAPI}
              addPhotoToFavoritesAPI={this.props.addPhotoToFavoritesAPI}
              removePhotoFromFavoritesAPI={
                this.props.removePhotoFromFavoritesAPI
              }
              userSignedIn={this.props.userSignedIn}
              userId={this.props.userId}
              userEmail={this.props.userEmail}
              discountMessage={this.props.discountMessage}
              updateFilters={this.updateFilters}
              unlockedPrivateEvent={this.state.unlockedPrivateEvent}
              sidebarOpen={this.state.sidebarOpen}
              curationMode={this.props.curationMode}
              listOfMediaId={this.props.listOfMediaId}
              setGuestUser={this.props.setGuestUser}
              guestUserId={this.props.guestUserId}
              activeFilters={this.activeFilters}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default EventContainer;
