import React from "react";

const ActiveIcon = (props: { color: string | undefined }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="9" y="9" width="7" height="7" rx="3.5" fill={props.color} />
      <g filter="url(#filter0_d_180_435)">
        <rect
          x="6"
          y="6"
          width="13"
          height="13"
          rx="6.5"
          fill="#D9D9D9"
          fillOpacity="0.01"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_180_435"
          x="0.2"
          y="0.2"
          width="24.6"
          height="24.6"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.447059 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_180_435"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_180_435"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default ActiveIcon;
