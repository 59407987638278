import React from "react";
const warnIcon = require("../../EventGalleryMobile/styles/icon-red-warn.svg");

interface BundleDiscountRemovePopupProps {
  removePhotoBundleDiscount: Function;
  hideRemoveOfferPrompt: Function;
}

const BundleDiscountRemovePopup = ({
  removePhotoBundleDiscount,
  hideRemoveOfferPrompt
}: BundleDiscountRemovePopupProps) => {
  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in geo-modal geo-modal--small geo-modal__remove-offer"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          &nbsp;
          <button
            type="button"
            className="close"
            onClick={() => {
              hideRemoveOfferPrompt();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="geo-modal__header geo-modal--warning-bg">
            <img
              className="geo-modal__warn-icon"
              src={warnIcon}
              alt="exclamation-warn"
            />
            <h4>Are you sure?</h4>
          </div>
          <div className="geo-modal__body">
            <p>
              If you remove this offer, you will have to pay full price for the
              photos in the cart.
            </p>
          </div>
          <div className="modal-footer geo-modal__actions">
            <a
              className="geo-btn geo-btn--link  geo-btn--caps"
              style={{
                backgroundColor: "#C1C1C1",
                color: "white",
                marginRight: 12
              }}
              onClick={() => removePhotoBundleDiscount()}
            >
              Remove Offer
            </a>
            <a
              className="geo-btn geo-btn--secondary geo-btn--caps"
              onClick={() => hideRemoveOfferPrompt()}
            >
              Keep Offer
            </a>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default BundleDiscountRemovePopup;
