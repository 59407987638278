import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ajax from "superagent";
import "../styles/customer-email-styles.css";
const largeWaitingClip = require("../styles/clip-waiting.svg");
const bannerUploadingClip = require("../styles/uploading-full.svg");

interface CollectCustomerEmailProps {
  registerCustomerLeadAPI: string;
  userSignedIn: boolean;
  userEmail: string;
  banner: boolean;
}

interface CollectCustomerEmailState {
  submitted: boolean;
  collapseBanner: boolean;
}

class CollectCustomerEmail extends React.Component<
  CollectCustomerEmailProps,
  CollectCustomerEmailState
> {
  customerEmailInput: React.RefObject<HTMLInputElement> | null;
  constructor(props: CollectCustomerEmailProps) {
    super(props);
    this.customerEmailInput = React.createRef();
    this.state = {
      submitted: false,
      collapseBanner: false
    };
  }
  static contextTypes = {
    cartId: PropTypes.number,
    eventCoverPickerMode: PropTypes.bool
  };

  registerCustomerEmail = () => {
    if (
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(
        //@ts-ignore
        this.customerEmailInput.current.value
      )
    ) {
      ajax
        .post(this.props.registerCustomerLeadAPI)
        .send({
          //@ts-ignore
          email: this.customerEmailInput.current.value,
          cart_id: this.context.cartId
        })
        .then(() => {
          this.setState({ submitted: true });
          setTimeout(() => this.setState({ collapseBanner: true }), 2000);
        })
        .catch(error => {
          //@ts-ignore
          toastr.error(error.response.body.error);
        });
    } else {
      //@ts-ignore
      toastr.error("The email you've entered is invalid.");
    }
  };

  renderCustomerEmailPrompt = () => {
    return this.props.userSignedIn ? (
      <div className="notify-button">
        <input
          type="hidden"
          value={this.props.userEmail}
          ref={this.customerEmailInput}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.registerCustomerEmail}
        >
          Notify me
        </button>
      </div>
    ) : (
      <div className="geo-form collect-customer-email-fields">
        <div className="geo-flex input-group geo-jcc notify-button">
          <input
            type="text"
            className="geo-form__input"
            placeholder="name@email.com"
            ref={this.customerEmailInput}
          />

          <button
            type="button"
            className="btn geo-btn btn-primary"
            onClick={this.registerCustomerEmail}
            style={{ marginLeft: 10 }}
          >
            Notify Me
          </button>
        </div>
      </div>
    );
  };

  renderFullSize = () => (
    <Fragment>
      {this.context.eventCoverPickerMode ? (
        <div className="collect-customer-email">
          <img src={largeWaitingClip} />
          <h1 style={{ marginTop: 25 }}>No photos here to select</h1>
        </div>
      ) : (
        <div className="collect-customer-email">
          <img src={largeWaitingClip} />
          <h1>Photos are not available yet.</h1>
          <p>Would you like to be notified by email when they are ready?</p>
          {!this.state.submitted ? (
            this.renderCustomerEmailPrompt()
          ) : (
            <div className="collect-customer-email-done">
              Thanks, we'll send you an email once the photos are ready.
            </div>
          )}{" "}
        </div>
      )}
    </Fragment>
  );

  renderBanner = () => (
    <div
      className={`dv-event-no-albums-banner ${
        this.state.collapseBanner ? "collapsed" : ""
      }`}
    >
      <div className="collect-customer-email-banner">
        <img src={bannerUploadingClip} />
        <div className="collect-customer-email-text">
          <h1>Photos are still being uploaded to this event.</h1>
          <p>
            You can get notified when all photos have been uploaded by providing
            your email
          </p>
        </div>
        {!this.state.submitted ? (
          this.renderCustomerEmailPrompt()
        ) : (
          <div className="collect-customer-email-done">
            Thanks, we'll send you an email once the photos are ready.
          </div>
        )}
        <i
          className="fa"
          onClick={_ => this.setState({ collapseBanner: true })}
        >
          ×
        </i>
      </div>
    </div>
  );

  render() {
    if (this.props.banner) return this.renderBanner();
    else return this.renderFullSize();
  }
}

export default CollectCustomerEmail;
