import React from "react";
import PropTypes from "prop-types";
import Album from "./Album";
import PasswordProtected from "./PasswordProtected";
import _ from "lodash";
import LazyLoad from "react-lazyload";
import CollectCustomerEmail from "../CollectCustomerEmail";
import PrivateScreen from "./PrivateScreen";
import ajax from "superagent";
import {
  setCookie,
  getCookie,
  withCartIdParam
} from "../../../../utils/BrowserHelper";
import { Album as AlbumType, Event } from "../types";
import FilteredResult from "./FilteredResult";
const gssNewLoader = require("../../../../assets/gss-loader.gif");
import Telemetry from "../../../../utils/rudderstackAnalytics";

interface AlbumsContainerProps {
  event: Event;
  photosAvailable: boolean;
  albums: AlbumType[];
  filterSelections: {
    albums: Array<{}>;
    photographers: Array<{}>;
    photoTime: {
      minuteFrom: number;
      minuteTo: number;
      active: boolean;
    };
    referenceImage?: string | null;
    favoritesSelected: boolean;
  };
  addPhotoToCart: (photo: any) => void;
  removePhotoFromCart: Function;
  cart?: {
    photos: Array<{}>;
    id: number;
  };
  discountMessage: string;
  registerCustomerLeadAPI: string;
  registerGuestUserAPI: string;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  userSignedIn: boolean;
  userId: number;
  userEmail: string;
  updateFilters: Function;
  unlockedPrivateEvent: boolean;
  sidebarOpen: boolean;
  curationMode: boolean;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  setGuestUser: (userId: number) => void;
  guestUserId: null | number;
  activeFilters: Function;
}

interface AlbumsContainerState {
  loadingAlbums: boolean;
  authenticated: boolean;
  albumPhotosVisibility: {};
  keyUpdateFlag: boolean;
  filteredAlbums: {
    id: number;
  }[];
  userInputPassword: string;
  filteredPhotos: any;
}

class AlbumsContainer extends React.Component<
  AlbumsContainerProps,
  AlbumsContainerState
> {
  static contextTypes = {
    iosEmbed: PropTypes.bool,
    uploadInProgress: PropTypes.bool,
    prepaidCustomer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      eventId: PropTypes.number
    }),
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      accentColor: PropTypes.string
    }),
    selfieSearchS3Bucket: PropTypes.string,
    bypassPasswordPrompt: PropTypes.bool,
    eventPassword: PropTypes.string,
    reset: PropTypes.bool,
    authenticateEventPasswordAPI: PropTypes.string,
    showPrepaidClearCartWarning: PropTypes.bool,
    promptClearPrepaidCart: PropTypes.func,
    selfieUploadPopup: PropTypes.bool,
    eventCoverPickerMode: PropTypes.bool,
    isEmbed: PropTypes.bool,
    cartId: PropTypes.number,
    resetPhotos: PropTypes.func,
    addAllToCart: PropTypes.func,
    setPhotos: PropTypes.func,
    hideUploadSelfie: PropTypes.bool,
    authenticationDone: PropTypes.func
  };

  constructor(props: AlbumsContainerProps) {
    super(props);

    this.state = {
      loadingAlbums: true,
      authenticated: false,
      albumPhotosVisibility: {},
      keyUpdateFlag: true,
      filteredAlbums: [],
      userInputPassword: "",
      filteredPhotos: []
    };
  }

  componentDidMount() {
    if (this.props.event.isProtected) this.authenticateEvent();
    else this.setState({ loadingAlbums: false });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const albumsChanged = this.props.albums !== prevProps.albums;
    const filterSelectionsChanged =
      this.props.filterSelections !== prevProps.filterSelections;
    const isAuthenticated =
      this.state.authenticated != prevState.authenticated ||
      this.state.authenticated;
    const isProtectedAndAuthenticated =
      (this.props.event.isProtected && isAuthenticated) ||
      !this.props.event.isProtected;
    const hasAuthenticationChanged =
      this.state.authenticated != prevState.authenticated;

    const shouldReloadAlbums =
      (albumsChanged && isProtectedAndAuthenticated) ||
      (filterSelectionsChanged && isProtectedAndAuthenticated) ||
      hasAuthenticationChanged;

    if (shouldReloadAlbums) {
      this.updateAlbumPhotosVisibility();
      this.setFilteredAlbums();

      if (this.props.filterSelections.favoritesSelected) {
        this.handleFavoritesSelected();
      }
    }
  }
  updateAlbumPhotosVisibility = () => {
    const { albums, filterSelections } = this.props;
    const albumPhotosVisibility = albums.reduce(
      (acc, album) => ({
        ...acc,
        [album.id]:
          filterSelections.albums.length > 0
            ? _.includes(filterSelections.albums, album.id)
            : true
      }),
      {}
    );
    this.setState({
      albumPhotosVisibility,
      keyUpdateFlag: !this.state.keyUpdateFlag
    });
  };

  trackPrivateEventSelfieUploadFlow = (trackEventName: string) => {
    Telemetry.track(trackEventName, {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      eoUserId: this.props.event.eoUserId
    });
  };
  trackSelfieFilterApplied = () => {
    Telemetry.track("event-gallery:filter-applied", {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      filterType: "selfie",
      embeddedMode: this.context.isEmbed
    });
  };

  handleFavoritesSelected = () => {
    this.setState({ loadingAlbums: true });
    this.loadPhotos(this.props.filterSelections, true);
  };

  toggleKey = (albumId: number, eventId: number) => {
    return this.state.keyUpdateFlag
      ? JSON.stringify(albumId)
      : JSON.stringify(albumId).concat(JSON.stringify(eventId));
  };

  unlockProtectedEvent = () => {
    this.setState(
      {
        authenticated: true
      },
      () => (
        this.context.authenticationDone && this.context.authenticationDone(),
        setCookie(
          "authenticated_event_password",
          `${this.state.userInputPassword}`
        )
      )
    );
  };

  authenticateEvent = () => {
    const storedEventPassword = getCookie("authenticated_event_password");

    ajax
      .get(this.context.authenticateEventPasswordAPI)
      .query({
        event_password: storedEventPassword
      })
      .then((res: any) => {
        let userAuthenticated =
          res.body.authenticated || !!this.context.bypassPasswordPrompt;

        this.setState(
          {
            userInputPassword: this.props.event.isProtected
              ? userAuthenticated
                ? getCookie("authenticated_event_password") ||
                  this.context.eventPassword
                : ""
              : "",
            authenticated: this.props.event.isProtected
              ? userAuthenticated
              : true
          },
          () => {
            this.state.authenticated &&
              this.context.authenticationDone &&
              this.context.authenticationDone();
            this.setState({ loadingAlbums: false });
          }
        );
      });
  };

  setFilteredAlbums = () => {
    if (this.props.filterSelections.albums.length > 0) {
      this.setState({
        filteredAlbums: _.filter(this.props.albums, album => {
          return _.includes(this.props.filterSelections.albums, album.id);
        })
      });
    } else if (this.props.filterSelections.photographers.length > 0) {
      this.setState({
        filteredAlbums: _.filter(this.props.albums, album => {
          return _.includes(
            this.props.filterSelections.photographers,
            album.photographerId
          );
        })
      });
    } else {
      this.setState({ filteredAlbums: this.props.albums });
    }
  };

  sideBarOpenStyling = (cssClass: string) =>
    this.props.sidebarOpen ? `col-md-9 ${cssClass}` : `col-md-11 ${cssClass}`;

  noAlbumPhotosVisible = () =>
    _.every(_.values(this.state.albumPhotosVisibility), a => !a);

  showPasswordComponent = () =>
    this.props.event.isProtected && !this.state.authenticated;

  renderCustomerLeadCollectionBanner = () =>
    this.context.uploadInProgress && (
      <CollectCustomerEmail
        registerCustomerLeadAPI={this.props.registerCustomerLeadAPI}
        userSignedIn={this.props.userSignedIn}
        userEmail={this.props.userEmail}
        banner={true}
      />
    );

  photosRequest = async (
    url: string,
    filters: {
      albums: Array<{}>;
      photoTime: { active: any; minuteFrom: any; minuteTo: any };
      referenceImage?: any;
      photoText?: any;
      favoritesSelected?: boolean;
    },
    page: number
  ) => {
    let req = ajax
      .get(this.props.cart ? withCartIdParam(url, this.props.cart!.id) : url)
      .query({
        page: page
      });

    if (this.state.userInputPassword !== "") {
      req = req.query({
        event_password: this.state.userInputPassword
      });
    }

    if (filters.photoTime.active) {
      req = req.query({
        minute_from: filters.photoTime.minuteFrom,
        minute_to: filters.photoTime.minuteTo
      });
    }

    if (filters.referenceImage) {
      req = req.query({
        reference_image: filters.referenceImage
      });
    }

    if (filters.albums.length > 0) {
      const albumIdsString = filters.albums
        .map(albumId => `album_ids[]=${albumId}`)
        .join("&");
      req = req.query(albumIdsString);
    }

    if (filters.photoText) {
      req = req.query({
        photo_text: filters.photoText
      });
    }
    if (this.props.userSignedIn) {
      req = req.query({
        user_id: this.props.userId
      });
    } else if (this.props.guestUserId) {
      req = req.query({
        user_id: this.props.guestUserId
      });
    }
    if (filters.favoritesSelected) {
      req = req.query({
        favorite: true
      });
    }

    return req;
  };

  loadPhotos = async (
    filters: {
      albums: Array<{}>;
      photoTime:
        | { active: any; minuteFrom: any; minuteTo: any }
        | { minuteFrom: number; minuteTo: number; active: boolean };
      referenceImage?: any;
      photoText?: any;
    },
    reset = false
  ) => {
    if (reset) {
      this.setState({
        loadingAlbums: true
      });
    }

    return await this.photosRequest(
      `/api/v1/events/${this.props.event.id}/photos`,
      filters,
      1
    )
      .then(response => {
        if (response.status == 200) {
          this.setState(
            {
              filteredPhotos: _.concat(
                reset ? [] : this.state.filteredPhotos,
                response.body.photos
              ),
              loadingAlbums: false
            },
            () => {
              const visiblePhotos = this.context.visblePhotos
                ? [...this.context.visblePhotos]
                : [];
              this.context.setPhotos(
                _.uniqBy([...visiblePhotos, ...this.state.filteredPhotos], "id")
              );
              if (this.props.curationMode) {
                this.context.resetPhotos();
              }
            }
          );
        }
      })
      .catch(err => console.log(err));
  };

  render() {
    if (this.state.loadingAlbums) {
      return (
        <div className="gss-loader album-container ">
          <img src={gssNewLoader} />
        </div>
      );
    } else if (!this.props.photosAvailable) {
      return (
        <div className="col-md-12 dv-results">
          <div className="dv-event-no-albums">
            <CollectCustomerEmail
              registerCustomerLeadAPI={this.props.registerCustomerLeadAPI}
              userSignedIn={this.props.userSignedIn}
              userEmail={this.props.userEmail}
              banner={false}
            />
          </div>
        </div>
      );
    } else if (this.showPasswordComponent()) {
      return (
        <>
          <div className="col-md-12 protected-header-styling">
            {this.renderCustomerLeadCollectionBanner()}
          </div>
          <PasswordProtected
            //@ts-ignore
            event={this.props.event}
            updateUserInputPassword={(password: any) =>
              this.setState({ userInputPassword: password })
            }
            unlockEvent={() => this.unlockProtectedEvent()}
            sideBarOpenStyling={this.sideBarOpenStyling}
          />
        </>
      );
    } else if (!this.props.unlockedPrivateEvent) {
      return (
        <>
          <div className="col-md-12 unlocked-header-styling">
            {this.renderCustomerLeadCollectionBanner()}
          </div>
          {!this.context.eventCoverPickerMode && (
            <div className="clearfix"></div>
          )}
          <PrivateScreen
            selfieSearchS3Bucket={this.context.selfieSearchS3Bucket}
            updateFilters={this.props.updateFilters}
            sideBarOpenStyling={this.sideBarOpenStyling}
            promptClearPrepaidCart={this.context.promptClearPrepaidCart}
            showPrepaidClearCartWarning={
              this.context.showPrepaidClearCartWarning
            }
            hideUploadSelfie={this.context.hideUploadSelfie}
            trackPrivateEventSelfieUploadFlow={
              this.trackPrivateEventSelfieUploadFlow
            }
            trackSelfieFilterApplied={this.trackSelfieFilterApplied}
            pageConfiguration={this.context.pageConfiguration}
          />
        </>
      );
    } else if (this.props.albums.length == 0) {
      return (
        <div className={this.sideBarOpenStyling("dv-results")}>
          <div className="dv-event-no-albums">
            Please wait while we are loading albums for you...
          </div>
        </div>
      );
    } else if (
      this.noAlbumPhotosVisible() ||
      (this.props.filterSelections.favoritesSelected &&
        this.state.filteredPhotos.length == 0)
    ) {
      return (
        <div className={this.sideBarOpenStyling("dv-results")}>
          {this.renderCustomerLeadCollectionBanner()}
          <div className="dv-event-no-albums">
            There are no photos matching your filters. Clear filters and try
            again.
          </div>
        </div>
      );
    } else if (this.props.filterSelections.favoritesSelected) {
      return (
        <div className={this.sideBarOpenStyling("dv-results")}>
          {this.renderCustomerLeadCollectionBanner()}
          <FilteredResult
            album={this.state.filteredAlbums}
            event={this.props.event}
            cart={this.props.cart}
            addPhotoToCart={this.props.addPhotoToCart}
            removePhotoFromCart={this.props.removePhotoFromCart}
            loadingAlbums={false}
            listOfMediaId={this.props.listOfMediaId}
            curationMode={this.props.curationMode}
            userId={this.props.userId}
            userSignedIn={this.props.userSignedIn}
            registerGuestUserAPI={this.props.registerGuestUserAPI}
            addPhotoToFavoritesAPI={this.props.addPhotoToFavoritesAPI}
            removePhotoFromFavoritesAPI={this.props.removePhotoFromFavoritesAPI}
            setGuestUser={this.props.setGuestUser}
            guestUserId={this.props.guestUserId}
            filteredPhotos={this.state.filteredPhotos}
            eventCoverPickerMode={this.context.eventCoverPickerMode}
            isEmbed={this.context.isEmbed}
            cartId={this.context.cartId}
            loadPhotos={this.loadPhotos}
            filterSelections={this.props.filterSelections}
            reset={this.context.reset}
            addAllToCart={this.context.addAllToCart}
            activeFilters={this.props.activeFilters}
            pageConfiguration={this.context.pageConfiguration}
          />
        </div>
      );
    } else {
      return (
        <div className={this.sideBarOpenStyling("dv-results")}>
          {this.renderCustomerLeadCollectionBanner()}
          {this.state.filteredAlbums.map((album: AlbumType) => (
            //@ts-ignore
            <LazyLoad
              height={200}
              offset={200}
              scrollContainer={
                this.context.iosEmbed
                  ? "#body-wrapper"
                  : (window as unknown as Element)
              }
              key={this.toggleKey(album.id, album.eventId)}
            >
              <Album
                filteredAlbums={this.state.filteredAlbums}
                event={this.props.event}
                album={album}
                filters={this.props.filterSelections}
                cart={this.props.cart}
                addPhotoToCart={this.props.addPhotoToCart}
                removePhotoFromCart={this.props.removePhotoFromCart}
                albumPhotosVisibility={(visible: any) =>
                  this.setState({
                    albumPhotosVisibility: {
                      ...this.state.albumPhotosVisibility,
                      [album.id]: visible
                    }
                  })
                }
                userInputPassword={this.state.userInputPassword}
                loadAPIFlag={this.toggleKey(album.id, album.eventId)}
                curationMode={this.props.curationMode}
                listOfMediaId={this.props.listOfMediaId}
                userId={this.props.userId}
                userSignedIn={this.props.userSignedIn}
                registerGuestUserAPI={this.props.registerGuestUserAPI}
                addPhotoToFavoritesAPI={this.props.addPhotoToFavoritesAPI}
                removePhotoFromFavoritesAPI={
                  this.props.removePhotoFromFavoritesAPI
                }
                setGuestUser={this.props.setGuestUser}
                guestUserId={this.props.guestUserId}
              />
            </LazyLoad>
          ))}
        </div>
      );
    }
  }
}

export default AlbumsContainer;
