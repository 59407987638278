import React, { Component } from "react";
import PropTypes from "prop-types";
import ajax from "superagent";
import _ from "lodash";
import ahoy from "ahoy.js";
import Photos from "./Photos";
import { withCartIdParam } from "../../../../utils/BrowserHelper";
import { Album as AlbumType } from "../types";
import { Event } from "../types";
import { LightBoxViewCart } from "../vectors/AddToCartIcon";
import ContrastForegroundUtility from "../../../../utils/ContrastForegroundUtility";

interface AlbumProps {
  event: Event;
  album: AlbumType;
  filters: {
    albums: Array<{}>;
    photographers: Array<{}>;
    photoTime: {
      minuteFrom: number;
      minuteTo: number;
      active: boolean;
    };
    referenceImage?: string | null;
    favoritesSelected: boolean;
  };
  addPhotoToCart: Function;
  removePhotoFromCart: Function;
  cart?: {
    photos: Array<{}>;
    id: number;
  };
  loadAPIFlag: string;
  userInputPassword: string | null;
  albumPhotosVisibility: Function;
  curationMode: boolean;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  filteredAlbums: any;
  userId: number;
  userSignedIn: boolean;
  registerGuestUserAPI: string;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  setGuestUser: (userId: number) => void;
  guestUserId: null | number;
}

interface AlbumState {
  photos: any;
  nextPage: number;
  loadingAlbums: boolean;
  loadingPhotos: boolean;
  hideAlbum: boolean;
  expanded: boolean;
  collapsibleGallery: boolean;
  galleryRows: any;
  totalCount?: number;
  showBulkTagging: boolean;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  resetState: boolean;
}

class Album extends Component<AlbumProps, AlbumState> {
  endMarkerEl(endMarkerEl: any) {
    throw new Error("Method not implemented.");
  }
  static contextTypes = {
    prepaidCustomer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      eventId: PropTypes.number
    }),
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      accentColor: PropTypes.string
    }),
    eventCoverPickerMode: PropTypes.bool,
    cartId: PropTypes.number,
    isEmbed: PropTypes.bool,
    callFunction: PropTypes.func,
    hidePhotographersFilter: PropTypes.bool,
    clearMediaIds: PropTypes.func,
    canManageTags: PropTypes.bool,
    visiblePhotos: PropTypes.array,
    reset: PropTypes.bool,
    resetPhotos: PropTypes.func,
    setPhotos: PropTypes.func,
    curationMode: PropTypes.bool
  };
  albumTopRef: React.LegacyRef<HTMLDivElement> | undefined;
  observer: any;

  constructor(props: AlbumProps) {
    super(props);

    this.state = {
      photos: [],
      nextPage: 1,
      loadingAlbums: true,
      loadingPhotos: false,
      hideAlbum: false,
      expanded: false,
      collapsibleGallery: false,
      galleryRows: 0,
      totalCount: undefined,
      showBulkTagging: false,
      listOfMediaId: this.props.listOfMediaId,
      resetState: false
    };

    this.albumTopRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      resetState: this.context.reset
    });
    this.loadPhotos(this.props.filters, true);
  }

  async componentDidUpdate(
    prevProps: {
      listOfMediaId: { id: number; hidden: boolean }[];
      guestUserId: null | number;
    },
    prevState: { resetState: boolean }
  ) {
    if (
      (this.context.canManageTags &&
        prevProps.listOfMediaId != this.props.listOfMediaId) ||
      prevState.resetState != this.context.reset
    ) {
      this.setState(
        {
          listOfMediaId: this.props.listOfMediaId,
          resetState: this.context.reset
        },
        async () => {
          if (this.context.curationMode && this.state.resetState === true) {
            if (!this.state.loadingAlbums)
              await this.loadPhotos(this.props.filters, true);
          }
        }
      );
    }
    if (this.props.guestUserId !== prevProps.guestUserId) {
      if (!this.state.loadingAlbums) {
        this.loadPhotos(this.props.filters, true);
      }
    }
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
  }

  photosRequest = async (
    url: string,
    filters: {
      albums: Array<{}>;
      photoTime: { active: any; minuteFrom: any; minuteTo: any };
      referenceImage?: any;
      photoText?: any;
      favoritesSelected?: boolean;
    },
    page: number
  ) => {
    let req = ajax
      .get(this.props.cart ? withCartIdParam(url, this.props.cart!.id) : url)
      .query({
        page: page
      });

    if (this.props.userInputPassword !== "") {
      req = req.query({
        event_password: this.props.userInputPassword
      });
    }

    if (filters.photoTime.active) {
      req = req.query({
        minute_from: filters.photoTime.minuteFrom,
        minute_to: filters.photoTime.minuteTo
      });
    }

    if (filters.referenceImage) {
      req = req.query({
        reference_image: filters.referenceImage
      });
    }

    if (filters.photoText) {
      req = req.query({
        photo_text: filters.photoText
      });
    }
    if (this.props.userSignedIn) {
      req = req.query({
        user_id: this.props.userId
      });
    } else if (this.props.guestUserId) {
      req = req.query({
        user_id: this.props.guestUserId
      });
    }
    if (filters.favoritesSelected) {
      req = req.query({
        favorite: true
      });
    }

    return req;
  };

  loadPhotos = async (
    filters: {
      albums: Array<{}>;
      photoTime:
        | { active: any; minuteFrom: any; minuteTo: any }
        | { minuteFrom: number; minuteTo: number; active: boolean };
      referenceImage?: any;
      photoText?: any;
    },
    reset = false
  ) => {
    if (reset) {
      this.setState({
        loadingAlbums: true,
        hideAlbum: false
      });
    }

    this.props.albumPhotosVisibility(true);
    return await this.photosRequest(
      this.props.album.links.photos,
      filters,
      reset ? 1 : this.state.nextPage
    )
      .then(response => {
        if (response.status == 200) {
          this.setState(
            {
              photos: _.concat(
                reset ? [] : this.state.photos,
                response.body.photos
              ),
              nextPage: response.body.meta.nextPage,
              totalCount: response.body.meta.totalCount,
              loadingAlbums: false,
              loadingPhotos: false,
              hideAlbum: response.body.meta.totalCount == 0
            },
            () => {
              if (this.context.canManageTags) {
                this.context.setPhotos(
                  _.uniqBy(
                    [...this.context.visiblePhotos, ...this.state.photos],
                    "id"
                  )
                );
              }
              if (this.context.curationMode) {
                this.context.resetPhotos();
              }
              this.props.albumPhotosVisibility(!this.state.hideAlbum);
            }
          );
        }
      })
      .catch(err => console.log(err));
  };

  updateFavorites = (photoId: number) => {
    const updatedPhotos = this.state.photos.map((photo: any) =>
      photo.id === photoId ? { ...photo, favorite: !photo.favorite } : photo
    );
    this.setState({ photos: updatedPhotos });
  };

  hasMorePhotos = () => this.state.nextPage != null;

  photosCount = () =>
    this.props.cart && this.props.cart.photos
      ? this.props.cart!.photos.length
      : 0;

  expandAlbum = () => {
    this.setState({ expanded: true }, () => {
      this.observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.intersectionRatio != 0 && this.hasMorePhotos()) {
              this.loadPhotos(this.props.filters);
              this.setState({ loadingPhotos: true });
            }
          });
        },
        { rootMargin: "400px 0px 0px 0px" }
      );
      this.observer.observe(this.endMarkerEl);
      ahoy.track("albumInteraction", {
        secondary_type: "expandAlbum",
        album_id: this.props.album.id,
        event_id: this.props.event.id
      });
    });
  };

  closeAlbum = () => {
    $("html, body").animate(
      {
        //@ts-ignore
        scrollTop: $(this.albumTopRef.current).offset().top - 70
      },
      500
    );
    this.observer.disconnect();
    this.setState({
      expanded: false,
      photos: _.take(this.state.photos, 100),
      nextPage: 2
    });
  };

  footer = () => {
    if (
      this.state.galleryRows <= 3 ||
      (this.state.galleryRows.rows && this.state.galleryRows.rows <= 3)
    )
      return null;

    return (
      <div
        className={`dv-section-footer ${
          this.state.expanded ? "expanded" : "collapsed"
        } ${this.state.loadingPhotos ? "loading" : ""}`}
      >
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            this.state.expanded ? this.closeAlbum() : this.expandAlbum();
          }}
        >
          {this.state.loadingPhotos ? (
            <React.Fragment>
              LOADING <span className="fa fa-circle-o-notch fa-spin" />
            </React.Fragment>
          ) : this.state.expanded ? (
            <React.Fragment>
              SEE LESS <span className="fa fa-chevron-up" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              SEE MORE <span className="fa fa-chevron-down" />
            </React.Fragment>
          )}
        </a>
      </div>
    );
  };

  render() {
    if (this.state.hideAlbum) return null;
    else
      return (
        <div className="dv-album-container" ref={this.albumTopRef}>
          <div
            className={
              "dv-album-details" +
              (this.context.eventCoverPickerMode ? " sticky-cover" : "")
            }
            translate="no"
          >
            <div className="info">
              <h3>{this.props.album.name}</h3>
              <div className="dv-photographer">
                <div className="dv-photographer">
                  {!this.context.hidePhotographersFilter && (
                    <React.Fragment>
                      <p>
                        By{" "}
                        <a
                          href={`mailto:${this.props.album.photographerEmail}?cc=support@geosnapshot.com&subject=Customer contact about event: ${this.props.event.name}`}
                        >
                          {this.props.album.photographerName}
                        </a>
                      </p>{" "}
                      <span style={{ color: "grey" }}>•</span>{" "}
                    </React.Fragment>
                  )}
                  <p>
                    {this.state.photos.length} of {this.state.totalCount} photos
                  </p>
                </div>
              </div>
            </div>
            {!this.context.eventCoverPickerMode && (
              <div className="checkout ns">
                <a
                  href={withCartIdParam(
                    "/checkout?source=event-gallery",
                    this.context.cartId,
                    this.context.isEmbed
                  )}
                  target={this.context.isEmbed ? "_blank" : ""}
                  className={
                    "btn  " +
                    (this.photosCount() > 0 ? " secondary-btn" : "disable-btn")
                  }
                  style={{
                    pointerEvents: this.photosCount() > 0 ? "initial" : "none"
                  }}
                >
                  <LightBoxViewCart
                    color={ContrastForegroundUtility.fgColor(
                      this.context.pageConfiguration.secondaryColor
                    )}
                  />
                  <span
                    className="photos"
                    style={{
                      color: `${ContrastForegroundUtility.fgColor(
                        this.context.pageConfiguration.secondaryColor
                      )}`
                    }}
                  >
                    &nbsp;{this.photosCount()}{" "}
                    <span className="cta-label-divider">|</span> View Cart
                  </span>
                </a>
              </div>
            )}
          </div>
          <div>
            <Photos
              event={this.props.event}
              album={this.props.album}
              photos={this.state.photos}
              cart={this.props.cart}
              addPhotoToCart={this.props.addPhotoToCart}
              removePhotoFromCart={this.props.removePhotoFromCart}
              loadingPhotos={this.state.loadingAlbums}
              loadMorePhotos={() => {
                if (this.hasMorePhotos()) {
                  this.loadPhotos(this.props.filters);
                }
              }}
              updateAlbum={(photoId: number) => {
                this.updateFavorites(photoId);
              }}
              collapsibleGallery={
                this.state.galleryRows > 3 ||
                (this.state.galleryRows.rows && this.state.galleryRows.rows > 3)
              }
              expanded={this.state.expanded}
              galleryRowsRendered={(rows: any) =>
                this.setState(prevState =>
                  prevState.galleryRows != rows ? { galleryRows: rows } : null
                )
              }
              listOfMediaId={this.props.listOfMediaId}
              curationMode={this.props.curationMode}
              userId={this.props.userId}
              userSignedIn={this.props.userSignedIn}
              registerGuestUserAPI={this.props.registerGuestUserAPI}
              addPhotoToFavoritesAPI={this.props.addPhotoToFavoritesAPI}
              removePhotoFromFavoritesAPI={
                this.props.removePhotoFromFavoritesAPI
              }
              setGuestUser={this.props.setGuestUser}
              guestUserId={this.props.guestUserId}
              filteredResult={false}
            />
            <div
              className="album-end-marker"
              ref={(el: any) => {
                this.endMarkerEl = el;
              }}
            ></div>
            {this.footer()}
          </div>
        </div>
      );
  }
}

export default Album;
